<template>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          OLAB ID:
          <span class="text-dark">{{ item.olab_id }}</span>
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          v-bind:disabled="clickDisabled == true"
          v-on:click="resetStates()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- .modal-header -->
      <div class="modal-body">
        <form v-on:submit.prevent="onSubmit">
          <fieldset class="form-group">
            <!-- <legend>{{selectedType}} Info</legend> -->
            <div class="form-group">
              <label class="form-control-label">
                Name:
              </label>
              <input
                class="form-control"
                v-bind:class="item.getValidState(item.name)"
                type="text"
                placeholder="Name"
                v-model="item.name"
                v-bind:disabled="clickDisabled == true"
              />
              <!-- <div class="invalid-feedback">{{invalidFB.name}}</div> -->
            </div>
            <div
              v-if="
                selectedType === 'cart' ||
                  selectedType === 'design' ||
                  selectedType === 'package' ||
                  selectedType === 'library_sequence'
              "
            >
              <label class="form-control-label">Project ID:</label>
              <span class="ml-1">{{ itemProjDetail }}</span>
            </div>
            <div v-if="selectedType !== 'user'" class="text-wrap">
              <label class="form-control-label">Creator:</label>
              <span class="ml-1">{{ item.creator }}</span>
            </div>
            <div v-if="selectedType === 'user'" class="text-wrap">
              <label class="form-control-label">Email:</label>
              <span class="ml-1">{{ item.email }}</span>
            </div>
            <div v-if="selectedType === 'user'" class="form-group">
              <div v-if="user.role && user.role === 'admin'">
                <label class="form-control-label">System Role:</label>
                <select
                  class="form-control"
                  v-bind:class="item.getValidState(item.role)"
                  v-model="item.role"
                >
                  <option disabled value="null">Please select one</option>
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                </select>
                <!-- <span>selected = {{ item.role }}</span> -->
              </div>
              <div v-else>
                <span class="mr-1">System Role:</span>
                <span class="h5 text-dark">{{ item.role }}</span>
              </div>
            </div>
            <div v-if="selectedType === 'user'" class="form-group">
              <label class="form-control-label">
                Employee ID:
              </label>
              <input
                class="form-control"
                v-bind:class="item.getValidState(item.user_id)"
                type="text"
                placeholder="Employee ID"
                v-model="item.user_id"
              />
            </div>
            <div
              v-if="
                selectedType === 'user' && user.role && user.role === 'admin'
              "
              class="form-group"
            >
              <label for="ip_cjPassword" class="form-control-label">
                <span>Password:</span>
              </label>
              <div class="form-row" id="ip_cjPassword">
                <section class="col-sm-6">
                  <input
                    class="form-control"
                    v-bind:class="item.getValidPWState(true)"
                    type="password"
                    required
                    placeholder="************"
                    v-model="item.passwd"
                  />
                  <div class="invalid-feedback">
                    {{ invalidFB.password }}
                  </div>
                </section>
                <section class="col-sm-6">
                  <input
                    class="form-control"
                    v-bind:class="item.getValidCPWState(true)"
                    type="password"
                    required
                    placeholder="************"
                    v-model="item.confirmed_passwd"
                  />
                  <div class="invalid-feedback">
                    {{ invalidFB.password_confirmed }}
                  </div>
                </section>
              </div>
            </div>

            <!-- Add photo upload when user's photo support it is ready -->
            <!-- <div v-if="selectedType === 'user'" class="form-group">
              <label class="form-control-label">
                Profile Photo:
              </label>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  v-on:change="changeFile($event, item, 'PHOTO')"
                />
              </div>
            </div> -->
            <div v-if="selectedType === 'project'" class="form-group">
              <label class="form-control-label">
                Start Date:
              </label>
              <input
                class="form-control"
                v-bind:class="item.getValidState(item.start_date)"
                type="date"
                v-model="item.start_date"
              />
              <!-- <div class="invalid-feedback">{{invalidFB.startDate}}</div> -->
            </div>
            <div v-if="selectedType === 'project'" class="form-group">
              <label class="form-control-label">End Date:</label>
              <input class="form-control" type="date" v-model="item.end_date" />
            </div>
            <div v-if="selectedType === 'library_sequence'" class="form-group">
              <label class="form-control-label">Library:</label>
              <span class="ml-1">{{ printLibraryInfo }}</span>
            </div>
            <div
              v-if="selectedType === 'library_sequence' && dbInfo.db_configs"
              class="form-group"
            >
              <label class="form-control-label">
                Sequence Origin:
              </label>
              <select
                class="form-control"
                v-bind:class="item.getValidState(item.seq_origin)"
                v-model="item.seq_origin"
                v-bind:disabled="clickDisabled == true"
              >
                <option disabled value="null">Please select one</option>
                <option
                  v-for="sOri in dbInfo.db_configs.seq_origins"
                  :key="sOri.olab_id"
                  >{{ sOri.name }} - {{ sOri.desc }}</option
                >
              </select>
              <!-- <div class="invalid-feedback">{{ invalidFB.seq_origin }}</div> -->
              <!-- <span>selected = {{item.seq_origin}}</span> -->
            </div>
            <div class="row">
              <div
                v-if="selectedType === 'plasmid' || selectedType === 'strain'"
                class="col-lg-4 text-wrap"
              >
                <label class="form-control-label">Source:</label>
                <span class="ml-1">{{ item.source }}</span>
              </div>
              <div v-if="selectedType === 'strain'" class="col-lg-4 text-wrap">
                <label class="form-control-label">Contig:</label>
                <span class="ml-1">{{ isContig }}</span>
              </div>
            </div>
            <div class="row">
              <div
                v-if="selectedType === 'plasmid' || selectedType === 'strain'"
                class="col-lg-4 text-wrap"
              >
                <div class="form-group">
                  <label class="form-control-label">WGS Confirmed:</label>
                  <div class="form-check form-check-inline mx-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      v-model="item.wgs_confirmed"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="
                  (selectedType === 'plasmid' ||
                    selectedType === 'project' ||
                    selectedType === 'strain') &&
                    item.ra_locks
                "
                class="col-lg-4 text-wrap"
              >
                <div class="form-group">
                  <label class="form-control-label">
                    <span>Public Access:</span>
                  </label>
                  <div class="form-check form-check-inline mx-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      v-model="item.ra_locks.public"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="selectedType === 'library_sequence'" class="form-group">
              <label class="form-control-label">Source:</label>
              <select
                class="form-control"
                v-model="item.source"
                v-bind:disabled="clickDisabled == true"
              >
                <option disabled value="null">Please select one</option>
                <option value="inhouse">inhouse</option>
                <option value="public">public</option>
              </select>
              <!-- <span>selected = {{item.source}}</span> -->
            </div>
            <div v-if="selectedType === 'plasmid'" class="text-wrap">
              <label class="form-control-label">Ori:</label>
              <span class="ml-1">{{ item.plasmid_ori }}</span>
            </div>
            <div v-if="selectedType === 'strain'">
              <div class="form-group card">
                <div class="card-header text-dark">Plasmid Data</div>
                <div class="card-body">
                  <div class="mb-2">
                    <div>
                      <span>
                        <label class="form-control-label">
                          Select Plasmid:
                        </label>
                      </span>
                      <span class="mx-2">
                        <select v-model="selectedPlasmid">
                          <option disabled value="null"
                            >Please select one</option
                          >
                          <option
                            v-for="plmd in availPlasmidArr"
                            :key="plmd.olab_id"
                            >{{ plmd.olab_id }} - {{ plmd.name }}
                          </option>
                        </select>
                      </span>
                      <span class="mx-2">
                        <button
                          type="button"
                          v-bind:disabled="plasmidAddIsDisabled == true"
                          class="btn btn-primary btn-sm"
                          v-on:click="addSelectedPlasmid(item)"
                        >
                          Add
                        </button>
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="item.plmd_array && item.plmd_array.length > 0"
                    class="mb-2"
                  >
                    <div class="list-group">
                      <div
                        class="list-group-item text-wrap"
                        v-for="plmd in item.plmd_array"
                        :key="plmd.index"
                      >
                        <span>{{ plmd }}</span>
                        <span class="mx-2">
                          <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            v-on:click="removePlasmid(plmd, item)"
                          >
                            Remove
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- .card-body -->
              </div>
              <!-- .card -->
            </div>
            <div v-if="selectedType === 'plasmid' || selectedType === 'strain'">
              <div class="form-group card">
                <div class="accordion" id="AccordionUploader">
                  <div class="card">
                    <div class="card-header" id="updateHeader">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#uploader"
                          aria-expanded="true"
                          aria-controls="uploader"
                          v-on:click="prepUploaderData(item)"
                        >
                          Update: {{ printUploaderHeader }}
                        </button>
                      </h2>
                    </div>

                    <div
                      id="uploader"
                      class="collapse"
                      aria-labelledby="updateHeader"
                      data-parent="#AccordionUploader"
                    >
                      <div class="card-body">
                        <div class="col-12 alert alert-danger text-center px-3">
                          Any existing data associated with this chromosome will
                          be replaced by the new chromosome data.
                        </div>
                        <div
                          v-if="selectedType === 'strain' && strnMcbe"
                          class="mb-2"
                        >
                          <div>
                            This strain can contain up to
                            {{ strnMcbe.chrm_count }} chromosome<span
                              v-if="strnMcbe.chrm_count > 1"
                              >s</span
                            >
                          </div>
                          <div
                            v-if="strnMcbe.chrm_count > 1"
                            class="form-group"
                          >
                            <label class="form-control-label"
                              >Chromosome:</label
                            >
                            <select
                              class="form-control"
                              v-model="selectedChrm"
                              v-bind:disabled="clickDisabled == true"
                            >
                              <option disabled value=""
                                >Please select one</option
                              >
                              <option
                                v-for="sMOV in strnMcbeOptVals"
                                :key="sMOV"
                                >{{ sMOV }}</option
                              >
                            </select>
                            <!-- <span>selected = {{ selectedChrm }}</span> -->
                          </div>
                        </div>
                        <div
                          v-if="
                            selectedType === 'plasmid' ||
                              (selectedType === 'strain' &&
                                ((strnMcbe && strnMcbe.chrm_count === 1) ||
                                  selectedChrm != ''))
                          "
                        >
                          <div v-if="selectedType === 'plasmid'">
                            <div class="mb-2">
                              <label class="form-control-label mr-2"
                                >Plasmid (genbank):</label
                              >
                              <input
                                type="file"
                                id="genbankFileInput"
                                accept=".gb, .gbk"
                                v-on:change="
                                  changeFile($event, item, 'GENBANK')
                                "
                              />
                            </div>
                            <h5 class="text-center my-2">
                              OR
                            </h5>
                          </div>
                          <div class="mb-2">
                            <label class="form-control-label mr-2"
                              >{{ printUploaderFasta }}:</label
                            >
                            <input
                              type="file"
                              id="fastaFileInput"
                              accept=".fasta"
                              v-on:change="changeFile($event, item, 'FASTA')"
                            />
                          </div>
                          <div>
                            <label class="form-control-label mr-2"
                              >Annotation data (csv):</label
                            >
                            <input
                              type="file"
                              id="csvFileInput"
                              accept=".csv"
                              v-on:change="changeFile($event, item, 'CSV')"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- .card-body -->
                      <div class="card-footer text-center">
                        <span class="px-1">Download CSV template:</span>
                        <a
                          v-if="isContig"
                          href="templates/contig_genes_template.csv"
                          target="_blank"
                          class="card-link"
                          download
                          >genes(contig)</a
                        >
                        <a
                          v-else
                          href="templates/genes_template.csv"
                          target="_blank"
                          class="card-link"
                          download
                          >genes</a
                        >
                      </div>
                      <!-- .card -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="selectedType === 'package'" class="py-1 text-wrap">
              <span class="mr-1">Package Type:</span>
              <span class="ml-1"
                >{{ item.package_type }} - {{ item.package_seq_type }}</span
              >
            </div>
            <div v-if="selectedType !== 'user'" class="py-1 form-group">
              <label class="form-control-label">Description:</label>
              <textarea
                class="form-control"
                v-model="item.desc"
                v-bind:disabled="clickDisabled == true"
              ></textarea>
            </div>
            <div
              v-if="selectedType === 'library_sequence' && !showConfirmDelete"
              class="card"
            >
              <div class="card-header text-dark">Sequence</div>
              <div v-if="item.seq" class="card-body">
                <div class="py-1 small text-monospace text-dark text-wrap">
                  {{ item.seq }}
                </div>
                <div v-if="item.seq" class="py-1">
                  <span class="small mr-1">Length:</span>
                  <span class="h5 small text-dark">{{ item.seq.length }}</span>
                </div>
              </div>
              <!-- .card-body -->
            </div>
            <!-- .card -->
            <div
              v-if="
                (selectedType === 'cart' || selectedType === 'package') &&
                  item.comps &&
                  !showConfirmDelete
              "
            >
              <OlabCompTable :selectedType="selectedType" :item="item" />
            </div>
          </fieldset>
          <!-- .form-group -->
        </form>
      </div>
      <!-- .modal-body -->
      <div v-if="showConfirmDelete" id="confirm-yes-no">
        <div class="alert alert-danger" role="alert">
          <h4 class="text-dark">Confirm {{ delNameStrLC }}</h4>
          <div class="card">
            <div class="card-body">
              Do you really want to {{ delNameStrLC }}?
              <br />
              {{ item.name }} <br />
              <p v-if="selectedType === 'library_sequence'">{{ item.seq }}</p>
              <br />
              <div class="text-center">
                <button
                  type="button"
                  class="btn btn-danger mx-2"
                  v-on:click="toggleDeleteItem(true)"
                >
                  Yes
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  v-on:click="toggleDeleteItem(false)"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="showCartIsSelected" id="confirm-yes-no">
        <div class="alert alert-danger" role="alert">
          <h4 class="text-dark">Unable to {{ delNameStrLC }}</h4>
          <div class="card">
            <div class="card-body">
              Sorry, we cannot delete a cart that is currently selected for
              design:<br />
              {{ item.name }} <br />
              <br />
              <p class="text-danger text-sm">
                To delete this cart you have to first unselect it from the
                Component module.
              </p>
              <br />
              <div class="text-center">
                <button
                  type="button"
                  class="btn btn-danger mx-2"
                  v-on:click="closeDeleteModal()"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          v-if="
            selectedType === 'cart' ||
              selectedType === 'library_sequence' ||
              selectedType === 'package' ||
              selectedType === 'user'
          "
          type="button"
          class="btn btn-danger"
          v-bind:disabled="clickDisabled == true"
          v-on:click="preDeleteItem()"
        >
          {{ delNameStr }}
        </button>
        <button
          type="button"
          v-bind:disabled="itemSaveIsDisabled == true || clickDisabled == true"
          class="btn btn-primary"
          data-dismiss="modal"
          v-on:click="callSaveItem()"
        >
          Save
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          v-bind:disabled="clickDisabled == true"
          v-on:click="resetStates()"
        >
          Cancel
        </button>
      </div>
      <!-- .modal-footer -->
    </div>
    <!-- .modal-content -->
  </div>
  <!-- .modal-dialog .modal-lg-->
</template>

<script>
import OlabCompTable from "@/components/OlabCompTable";
import { OlabItem } from "../olab/olabitem.js";
import { OlabUtils } from "../olab/olabutils.js";
import $ from "jquery";

export default {
  name: "olabedititem",
  components: {
    OlabCompTable
  },
  data: function() {
    return {
      strnMcbe: null,
      strnMcbeOptVals: [],
      selectedChrm: "",
      selectedPlasmid: null,
      showConfirmDelete: false,
      showCartIsSelected: false,
      invalidFB: OlabItem.getInvalidFeedbacks()
    };
  },
  props: [
    "user",
    "dbInfo",
    "selectedType",
    "item",
    "itemProjDetail",
    "availPlasmidArr"
  ],
  computed: {
    isContig: function() {
      return this.item.contig_array && this.item.contig_array.length > 0;
    },
    clickDisabled: function() {
      return this.showCartIsSelected || this.showConfirmDelete;
    },
    delNameStr: function() {
      return this.item.deleted ? "Undelete" : "Delete";
    },
    delNameStrLC: function() {
      return this.delNameStr ? this.delNameStr.toLowerCase() : "";
    },
    plasmidAddIsDisabled: function() {
      return !this.selectedPlasmid;
    },
    itemSaveIsDisabled: function() {
      return (
        !this.item.isItemValid(this.selectedType, false) || this.item.deleted
      );
    },
    printLibraryInfo: function() {
      if (
        this.dbInfo &&
        this.dbInfo.db_configs &&
        this.dbInfo.db_configs.libraries
      ) {
        const libs = this.dbInfo.db_configs.libraries;
        for (let i = 0; i < libs.length; i++) {
          if (libs[i].olab_id == this.item.library_id) {
            return libs[i].olab_id + " - " + libs[i].name;
          }
        }
      }
      return "";
    },
    printUploaderHeader: function() {
      return this.item.olab_type === "plasmid"
        ? "Plasmid Data"
        : "Chromosome Data";
    },
    printUploaderFasta: function() {
      return this.item.olab_type === "plasmid"
        ? "Plasmid sequence (fasta)"
        : "Chromosome sequence (fasta)";
    }
  },
  methods: {
    prepUploaderData: async function(item) {
      // console.log("olab_type =", item.olab_type, ", item =", item);
      // Load strain's microbe (parent) if item is a strain
      if (item.olab_type === "strain") {
        // Extract microbe_id for olab_id
        const microbeID = item.olab_id.substring(0, 3);
        // console.log("MicrobeID =", microbeID);
        const mcbeArr = await OlabUtils.getMicrobes({
          params: {
            olab_id: microbeID
          }
        });
        if (mcbeArr.length > 0) {
          this.strnMcbe = mcbeArr[0];
          // console.log("mcbeArr[0] =", mcbeArr[0]);
          // console.log(`mcbeArr[0].chrm_count = ${mcbeArr[0].chrm_count}`);
          // console.log("selected Strain's chrm_array =", item.chrm_array);
          // console.log("selected Strain =", item);
          // Generate a option values for v-for based on microbe's chrm_count
          const optVals = [];
          for (let i = 0; i < mcbeArr[0].chrm_count; i++) {
            const padIPlus1 = String(i + 1).padStart(2, "0");
            optVals.push(`C${padIPlus1}`);
          }
          this.strnMcbeOptVals = optVals;
        } else {
          console.log(`Error! No microbe found with olab_id = ${microbeID}`);
        }
      }
    },
    changeFile: function(e, item, fileType) {
      const selectedFile = e.target.files[0];
      // console.log(
      //   "changeFile - selectedFile =",
      //   selectedFile,
      //   ", item =",
      //   item,
      //   ", fileType =",
      //   fileType
      // );
      if (fileType === "CSV") {
        item.csv_file = selectedFile ? selectedFile : null;
      } else if (fileType === "FASTA") {
        item.fasta_file = selectedFile ? selectedFile : null;
      } else if (fileType === "GENBANK") {
        item.genbank_file = selectedFile ? selectedFile : null;
      } else if (fileType === "PHOTO") {
        item.photo = selectedFile ? selectedFile : null;
      }
    },
    preDeleteItem: function() {
      if (
        this.selectedType === "cart" &&
        this.user &&
        this.user.selected &&
        this.user.selected.cart_id === this.item.olab_id
      ) {
        // console.log("Cart ID =", this.item.olab_id);
        this.showCartIsSelected = true;
      } else {
        this.showConfirmDelete = true;
      }
    },
    toggleDeleteItem: function(res) {
      if (res) {
        if (this.item.olab_type === "user") {
          // console.log("toggleDeleteItem: item =", this.item);
          // Implement delete User as real delete operation
          this.$emit("handleDeleteItem");
        } else {
          // Toggle delete and call save item
          this.item.deleted = !this.item.deleted;
          this.$emit("handleSaveItem");
        }
        $("#selectEditItem").modal("hide");
      }
      this.showConfirmDelete = false;
    },
    closeDeleteModal: function() {
      // $("#selectEditItem").modal("hide");
      this.showCartIsSelected = false;
    },
    callSaveItem: function() {
      if (this.selectedType === "strain") {
        // Piggyback on item using selectedChrm value
        if (this.strnMcbe && this.strnMcbe.chrm_count === 1) {
          this.item.selectedChrm = "C01";
        } else if (this.selectedChrm != "") {
          this.item.selectedChrm = this.selectedChrm;
        }
      }
      this.$emit("handleSaveItem");
      this.resetStates();
    },
    addSelectedPlasmid: function(item) {
      // console.log("addSelectedPlasmid =", this.selectedPlasmid);
      // console.log("item =", item);
      // console.log(item.plmd_array);
      const plmdID = OlabUtils.subStrB4Space(this.selectedPlasmid);
      // console.log("plmdID", plmdID);
      if (!item) {
        return;
      } else if (!item.plmd_array) {
        item.plmd_array = [plmdID];
      } else {
        item.plmd_array.push(plmdID);
      }
      this.$emit("updatePlasmidArrs", {
        mode: "ADDED",
        id: plmdID
      });
      this.selectedPlasmid = null;
    },
    removePlasmid: function(plmdID, item) {
      // console.log("1 removePlasmid", item.plmd_array);
      const idx = item.plmd_array.indexOf(plmdID);
      // console.log("idx =", idx);

      if (idx >= 0) {
        item.plmd_array.splice(idx, 1);
      }
      // console.log("2 removePlasmid", item.plmd_array);
      this.$emit("updatePlasmidArrs", {
        mode: "REMOVED",
        id: plmdID
      });
    },
    resetStates: function() {
      if (this.selectedType === "plasmid" || this.selectedType === "strain") {
        // console.log("Calling resetStates ...");
        const isVisible = $("#uploader").is(":visible");
        // console.log("uploader: isVisible = ", isVisible);
        if (isVisible) {
          $("#uploader").collapse("toggle");
          // console.log("uploader: toggled!");
        }
        this.strnMcbe = null;
        this.strnMcbeOptVals = [];
        this.selectedChrm = "";
        // Clear input file value
        $("#fastaFileInput").val("");
        $("#csvFileInput").val("");
        // TODO: Only use for plasmid for now
        if (this.selectedType === "plasmid") {
          $("#genbankFileInput").val("");
        }
      }
    },
    onSubmit() {
      // This is a NOP to prevent page reload on return key
      // console.log("After Submit");
    }
  }
};
</script>
