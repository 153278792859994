<template>
  <div>
    <form class="mt-3" @submit.prevent="login">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="card bg-light">
              <div class="card-body">
                <h3 class="font-weight-light mb-3">Log in</h3>
                <section class="form-group">
                  <div
                    v-if="errorObj.message"
                    class="col-12 alert alert-danger text-center px-3"
                  >
                    {{ errorObj.message }}
                    <div class="text-center mt-2">
                      <button
                        type="button"
                        class="btn btn-danger"
                        v-on:click="closeError()"
                      >
                        OK
                      </button>
                    </div>
                  </div>
                  <label class="form-control-label sr-only" for="Email"
                    >Email</label
                  >
                  <input
                    class="form-control"
                    v-bind:class="validEmailState()"
                    type="email"
                    id="email"
                    placeholder="Email Address"
                    required
                    v-model="email"
                  />
                  <div class="invalid-feedback">{{ invalidFB.email }}</div>
                </section>
                <section class="form-group">
                  <input
                    class="form-control"
                    v-bind:class="validPasswordState()"
                    type="password"
                    required
                    placeholder="Password"
                    v-model="password"
                  />
                  <div class="invalid-feedback">{{ invalidFB.password }}</div>
                </section>
                <div class="form-group text-right mb-0">
                  <button class="btn btn-primary" type="submit">Log in</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <p class="text-center mt-2">
      <span>
        <!-- <router-link to="/register">register</router-link>
        or
        <router-link to="/forgotpassword">forgot password?</router-link>
        or -->
        Forgot password? Please contact
        <a
          href="mailto:youngnyun.kim@olab.bio?subject=Required Action: Forgot Password"
          >support</a
        >
        for assistance
      </span>
    </p>
  </div>
</template>

<script>
import axios from "axios";
import { OlabUtils } from "../olab/olabutils";

export default {
  data: function() {
    return {
      email: "",
      password: "",
      wrongEmail: false,
      wrongPassword: false,
      invalidFB: {
        email: "User email not found.",
        password:
          "Wrong password. Try again or click forgot password to reset it."
      }
    };
  },
  props: ["errorObj"],
  methods: {
    validEmailState: function() {
      let result = null;
      result = this.wrongEmail ? "is-invalid" : null;
      return result;
    },
    validPasswordState: function() {
      let result = null;
      result = this.wrongPassword ? "is-invalid" : null;
      return result;
    },
    login: async function() {
      const info = {
        email: this.email,
        password: this.password
      };
      // alert(`${info.email}, ${info.password}`);
      // console.log("Login: Implement login", info);
      try {
        const res = await axios({
          method: "POST",
          url: "/api/v1/users/login",
          data: {
            email: info.email,
            password: info.password
          }
        });
        OlabUtils.infoLog(res.data.data.user);
        if (res.data.status === "success") {
          this.$emit("setUser", res.data.data.user);
          setTimeout(() => {
            this.$router.push("/");
          }, 100);
        }
      } catch (err) {
        // OlabUtils.errorLog(err.response.data.message, err);
        this.errorObj.message = OlabUtils.getErrorMessage(err);
      }
    },
    closeError: function() {
      // console.log("Close Error Alert ...");
      this.errorObj.message = null;
    }
  },
  watch: {
    email: function() {
      // OlabUtils.infoLog("email = " + this.email);
      // Reset
      if (this.wrongEmail) {
        this.wrongEmail = false;
      }
    },
    password: function() {
      // OlabUtils.infoLog("password = " + this.password);
      // Reset
      if (this.wrongPassword) {
        this.wrongPassword = false;
      }
    }
  }
};
</script>
