<template>
  <div class="mt-3">
    <div
      v-if="errorObj.message"
      class="col-12 alert alert-danger text-center px-3"
    >
      {{ errorObj.message }}
      <div class="text-center mt-2">
        <button type="button" class="btn btn-danger" v-on:click="closeError()">
          OK
        </button>
      </div>
    </div>
    <div class="text-secondary text-center mt-3">
      <img alt="OLAB Bioscience logo" src="../assets/OLAB.svg" />
      <h6 v-if="dbInfo" class="font-weight-bold text-brand-color my-2">
        {{ dbInfo.owner }}
      </h6>
    </div>
    <div>
      <h3 v-if="dbInfo" class="text-secondary text-center my-2">
        {{ dbInfo.name }} Private Cloud
      </h3>
      <div v-if="user" class="text-center mb-2 h4">
        <span>
          Welcome:
        </span>
        <span class="font-weight-bold text-info">{{ user.name }}</span>
      </div>
    </div>
    <div v-if="olabServerInfo" class="container text-center">
      <div class="row justify-content-center">
        <div class="col-10 col-md-10 col-lg-8 col-xl-7">
          <p class="lead text-danger mt-2" v-if="!user">
            Members Only Site
          </p>
          <router-link
            class="btn btn-outline-primary mr-2"
            to="/login"
            v-if="!user"
            >Login</router-link
          >
        </div>
      </div>
    </div>
    <div v-if="user" class="mt-3 container text-center">
      <ul class="nav nav-tabs" id="reportTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="olab-info-tab"
            data-toggle="tab"
            href="#olab-info"
            role="tab"
            aria-controls="olab-info"
            aria-selected="true"
            >Versions
          </a>
        </li>
        <li
          v-if="
            dbInfo &&
              olabServerInfo &&
              olabServerInfo.show_progress_status === 'true'
          "
          class="nav-item"
        >
          <a
            class="nav-link"
            id="olab-status-tab"
            data-toggle="tab"
            href="#olab-status"
            role="tab"
            aria-controls="olab-status"
            aria-selected="true"
            >Status
          </a>
        </li>
        <li v-if="user.role === 'admin'" class="nav-item">
          <a
            class="nav-link"
            id="olab-stats-tab"
            data-toggle="tab"
            href="#olab-stats"
            role="tab"
            aria-controls="olab-stats"
            aria-selected="false"
            >Stats
          </a>
        </li>
      </ul>
      <div class="tab-content" id="reportTabContent">
        <div
          class="tab-pane fade show active"
          id="olab-info"
          role="tabpanel"
          aria-labelledby="olab-info-tab"
        >
          <div class="form-group mt-3">
            <div class="container">
              <div v-if="user" class="text-center">
                <div v-if="olabServerInfo && dbInfo">
                  <h5 class="mb-2">
                    <span class="text-success">{{ dbInfo.name }} Server</span>
                    <span class="text-primary mx-2"
                      >version: {{ olabServerInfo.version }}</span
                    >
                  </h5>
                  <h5 class="mb-2">
                    <span class="text-success">DB Schema</span>
                    <span class="text-primary mx-2"
                      >version: {{ dbInfo.version }}</span
                    >
                  </h5>
                </div>
                <div v-else class="alert alert-danger" role="alert">
                  <h5 class="mb-2">
                    <span>OLAB Server: Connection refused</span>
                  </h5>
                </div>
                <div
                  v-if="bioServerInfo"
                  class="alert alert-warning"
                  role="alert"
                >
                  <h5 class="mb-2">
                    <span>Bio Server</span>
                    <span class="mx-2"
                      >version: {{ bioServerInfo.version }}</span
                    >
                  </h5>
                </div>
                <div v-else class="alert alert-danger" role="alert">
                  <h5 class="mb-2">
                    <span>Bio Server: Connection refused</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            dbInfo &&
              olabServerInfo &&
              olabServerInfo.show_progress_status === 'true'
          "
          class="tab-pane fade"
          id="olab-status"
          role="tabpanel"
          aria-labelledby="olab-status-tab"
        >
          <div class="form-group mt-3">
            <OlabStatus />
          </div>
        </div>
        <div
          v-if="user.role === 'admin'"
          class="tab-pane fade"
          id="olab-stats"
          role="tabpanel"
          aria-labelledby="olab-stats-tab"
        >
          <div class="form-group mt-3">
            <OlabStats
              :user="user"
              :dbInfo="dbInfo"
              :statusObj="statusObj"
              :errorObj="errorObj"
            />
          </div>
        </div>
      </div>
      <div class="my-2">
        <p>
          Please submit your questions, bugs, feature requests, and
          contributions to
          <a href="#" v-on:click="mailTo">support at OLAB</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import OlabStatus from "@/components/status/OlabStatus";
import OlabStats from "@/components/stats/OlabStats";

export default {
  name: "home",
  props: [
    "user",
    "olabServerInfo",
    "bioServerInfo",
    "dbInfo",
    "statusObj",
    "errorObj"
  ],
  components: {
    // FontAwesomeIcon
    OlabStatus,
    OlabStats
  },
  methods: {
    mailTo: function() {
      // console.log("process.env =", process.env);
      window.location.href = `mailto:youngnyun.kim@olab.bio?subject=Required Action: ${process.env.VUE_APP_PROJECT} Project`;
    },
    closeError: function() {
      // console.log("Close Error Alert ...");
      this.errorObj.message = null;
    }
  }
};
</script>
