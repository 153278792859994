var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[(_vm.errorObj.message)?_c('div',{staticClass:"col-12 alert alert-danger text-center px-3"},[_vm._v(" "+_vm._s(_vm.errorObj.message)+" "),_c('div',{staticClass:"text-center mt-2"},[_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.closeError()}}},[_vm._v(" OK ")])])]):_vm._e(),_c('div',{staticClass:"text-secondary text-center mt-3"},[_c('img',{attrs:{"alt":"OLAB Bioscience logo","src":require("../assets/OLAB.svg")}}),(_vm.dbInfo)?_c('h6',{staticClass:"font-weight-bold text-brand-color my-2"},[_vm._v(" "+_vm._s(_vm.dbInfo.owner)+" ")]):_vm._e()]),_c('div',[(_vm.dbInfo)?_c('h3',{staticClass:"text-secondary text-center my-2"},[_vm._v(" "+_vm._s(_vm.dbInfo.name)+" Private Cloud ")]):_vm._e(),(_vm.user)?_c('div',{staticClass:"text-center mb-2 h4"},[_c('span',[_vm._v(" Welcome: ")]),_c('span',{staticClass:"font-weight-bold text-info"},[_vm._v(_vm._s(_vm.user.name))])]):_vm._e()]),(_vm.olabServerInfo)?_c('div',{staticClass:"container text-center"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-10 col-md-10 col-lg-8 col-xl-7"},[(!_vm.user)?_c('p',{staticClass:"lead text-danger mt-2"},[_vm._v(" Members Only Site ")]):_vm._e(),(!_vm.user)?_c('router-link',{staticClass:"btn btn-outline-primary mr-2",attrs:{"to":"/login"}},[_vm._v("Login")]):_vm._e()],1)])]):_vm._e(),(_vm.user)?_c('div',{staticClass:"mt-3 container text-center"},[_c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"reportTab","role":"tablist"}},[_vm._m(0),(
          _vm.dbInfo &&
            _vm.olabServerInfo &&
            _vm.olabServerInfo.show_progress_status === 'true'
        )?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"id":"olab-status-tab","data-toggle":"tab","href":"#olab-status","role":"tab","aria-controls":"olab-status","aria-selected":"true"}},[_vm._v("Status ")])]):_vm._e(),(_vm.user.role === 'admin')?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"id":"olab-stats-tab","data-toggle":"tab","href":"#olab-stats","role":"tab","aria-controls":"olab-stats","aria-selected":"false"}},[_vm._v("Stats ")])]):_vm._e()]),_c('div',{staticClass:"tab-content",attrs:{"id":"reportTabContent"}},[_c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"olab-info","role":"tabpanel","aria-labelledby":"olab-info-tab"}},[_c('div',{staticClass:"form-group mt-3"},[_c('div',{staticClass:"container"},[(_vm.user)?_c('div',{staticClass:"text-center"},[(_vm.olabServerInfo && _vm.dbInfo)?_c('div',[_c('h5',{staticClass:"mb-2"},[_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.dbInfo.name)+" Server")]),_c('span',{staticClass:"text-primary mx-2"},[_vm._v("version: "+_vm._s(_vm.olabServerInfo.version))])]),_c('h5',{staticClass:"mb-2"},[_c('span',{staticClass:"text-success"},[_vm._v("DB Schema")]),_c('span',{staticClass:"text-primary mx-2"},[_vm._v("version: "+_vm._s(_vm.dbInfo.version))])])]):_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._m(1)]),(_vm.bioServerInfo)?_c('div',{staticClass:"alert alert-warning",attrs:{"role":"alert"}},[_c('h5',{staticClass:"mb-2"},[_c('span',[_vm._v("Bio Server")]),_c('span',{staticClass:"mx-2"},[_vm._v("version: "+_vm._s(_vm.bioServerInfo.version))])])]):_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._m(2)])]):_vm._e()])])]),(
          _vm.dbInfo &&
            _vm.olabServerInfo &&
            _vm.olabServerInfo.show_progress_status === 'true'
        )?_c('div',{staticClass:"tab-pane fade",attrs:{"id":"olab-status","role":"tabpanel","aria-labelledby":"olab-status-tab"}},[_c('div',{staticClass:"form-group mt-3"},[_c('OlabStatus')],1)]):_vm._e(),(_vm.user.role === 'admin')?_c('div',{staticClass:"tab-pane fade",attrs:{"id":"olab-stats","role":"tabpanel","aria-labelledby":"olab-stats-tab"}},[_c('div',{staticClass:"form-group mt-3"},[_c('OlabStats',{attrs:{"user":_vm.user,"dbInfo":_vm.dbInfo,"statusObj":_vm.statusObj,"errorObj":_vm.errorObj}})],1)]):_vm._e()]),_c('div',{staticClass:"my-2"},[_c('p',[_vm._v(" Please submit your questions, bugs, feature requests, and contributions to "),_c('a',{attrs:{"href":"#"},on:{"click":_vm.mailTo}},[_vm._v("support at OLAB")])])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"olab-info-tab","data-toggle":"tab","href":"#olab-info","role":"tab","aria-controls":"olab-info","aria-selected":"true"}},[_vm._v("Versions ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"mb-2"},[_c('span',[_vm._v("OLAB Server: Connection refused")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"mb-2"},[_c('span',[_vm._v("Bio Server: Connection refused")])])
}]

export { render, staticRenderFns }